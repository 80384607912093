.causesContainer{
    position: relative;
    margin: 50px 0px;
    .text{
        font-family: var(--text2Family);
        line-height: 30px;
        font-size: 14px;
        padding-bottom: 20px;
    }
    .title{
        font-family: var(--textFamily);
        color: var(--blackColor);
        font-size: 20px;
        font-weight: 700;
        padding: 10px 0px;
        position: relative; 
    }
    .inner-box{
        margin-bottom: 30px;
        .image{
            position: relative;
            overflow: hidden;
            display: inline-block;
            margin: 0;
            img{
                display: inline-block;
                width: 100%;
            }
            .overlay {
                position: absolute;
                text-align: center;
                left: 0px;
                top: 0px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                width: 100%;
                height: 0%;
                background: rgba(57, 59, 57, 0.8);
                -webkit-transition: .7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
                -o-transition: .7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
                transition: .7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
                opacity: 1;
                visibility: hidden;
                .buttonNav{
                    transition: all -0.5s ease;
                    -moz-transition: all -0.5s ease;
                    -webkit-transition: all -0.5s ease;
                }
            }
        }
        .lower-content{
            position: relative;
            padding: 40px 20px;
            background: var(--lightBackground);
            border-top: 0px;
            margin-top: -7px;

            
            span {   
                position: relative;
                display: block;
                align-items: center;
                text-align: left;
                top:-5px;
                padding-bottom: 20px;
            }
            span::before{
                position: absolute;
                content: '';
                background-color: var(--primaryColor);
                height: 2px;
                width: 15px;
                top: 0px;
                left: 0%;
                transition: 0.4s all ease;
            }
            span::after{
                position: absolute;
                content: '';
                background-color: var(--primaryColor);
                height: 2px;
                width: 30px;
                top: 5px;
                left: 0%;
            }
           
        }
    }
    .inner-box:hover{
        .overlay{
            visibility: visible;
            height: 100%;
        }
        .lower-content{
            span:before{
                width: 50px;
            }
        }
    }

    .pageHeaderContainer {
        .smallLogoDesign{
            align-items: flex-start;
            justify-content: flex-start;

            .firstBefore{
                top: 0px;
                left: -10px;
                padding-right: 40px;
            }
            .secondBefore{
                top: 0px;
            }

            @media screen and (max-width:500px) {
                .secondBefore{
                    display: none;
                }
            }
        }
        .pageTitle{
            text-align: left!important;
        }
    }
    .keywordsContainer{
        padding-top: 30px;
        span{
            padding-right: 10px;
            .icon{
                color: var(--orangeColor)
            }
        }
    }
}