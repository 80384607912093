.pageBanner{
    background-image: url('../../Images/pageBanner.jpg');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    min-height: 300px;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: var(--textFamily);
    .page-content{
        h2{
            font-size: xx-large;
            font-weight: 600;
        }
        p{
            color: #ddd;
            width: 55%;
            font-size: 14px;
        }
        .links{
            display: flex;
            gap: 10px;
            align-items: center;
            font-size: 16px;
            .home{
                display: flex;
                align-items: center;
                gap: 3px;
                .link{
                    color: #fff;
                    text-decoration: none;
                }
            }
            .slash{
                color: #6b6a6a;
                font-size: 12px;
            }
            .pageName{
                color: var(--primaryColor);
                text-transform: uppercase;
            }

        }
    }
}