.contactPageContainer{
    position: relative;
    background: var(--lightBackground);
    padding: 50px 0px;
    .title{
        font-family: var(--textFamily);
        color: var(--blackColor);
        font-size: 20px;
        font-weight: 600;
        position: relative;
        padding-bottom: 7px;
        margin-bottom: 15px;
    }
    .title:before{
        position: absolute;
        content: '';
        left: 0;
        bottom: -5px;
        width: 30px;
        height: 2px;
        background-color: var(--primaryColor);
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
    }
    .title:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 2px;
        width: 15px;
        height: 2px;
        background-color: var(--primaryColor);
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
    }

    .text{
        padding: 20px 0px;
        font-family: var(--text2Family);
        font-size: 15px;
        color: var(--lightBlack);
        line-height: 27px;
    }
    .contactWrapper{
        background: #fff;
        padding: 40px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
    .getinTouch{
        display: flex;
        background: var(--lightBackground);
        margin: 10px 0px;
        gap: 20px;
        padding: 10px;
        .first{
            flex: 1;
            background: var(--orangeColor);
            height: 60px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon{
                color:#fff;
                font-size: 22px;
            }
        }
        .second{
            flex: 7;
            .title{
                font-size: 17px;
                margin-bottom: 2px;
            }
            .text{
                padding: 20px 0px 2px;
                a{
                    text-decoration: none;
                    color: var(--lightBlack)
                }
            }
        }
    }
    .socialUrl{
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 20px;
        .social-link a{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: var(--lightBackground);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--primaryColor);
            font-size: 20px;
            transition: 0.4s all ease;
        }
        .social-link a:hover{
            background: var(--primaryColor);
            color: #fff;
        }
    }
    .formContainer{
        position: relative;
        width: 100%;
        .inputText{
            width: 100%;
            margin-bottom: 20px;
            height: 40px;
            padding-left: 10px;
            border: 1px solid var(--lightBlack);
            font-family: var(--text2Family);
            color: #535252;
            font-size: 16px;
            outline: none;
        }
        .inputText:focus,
        .textarea:focus{
            border: 1px solid var(--primaryColor)
        }
        .textarea{
            width: 100%;
            margin-bottom: 15px;
            padding-left: 10px;
            border: 1px solid var(--lightBlack);
            font-family: var(--text2Family);
            color: #535252;
            font-size: 16px;
            outline: none;
        }
        .errorBorder{
            border: 1px solid crimson
        }
        p{
            position: relative;
            top:-20px;
            color: crimson;
            font-size: 13px;
            font-style: italic;
            font-family: var(--text2Family);
        }
        .message{
            width: 100%;
            height: 50px;
            background: gray;
            margin: 10px 0px;
            color:#fff;
            .success{
                background: var(--primaryColor);
                height: 100%;
                display: flex;
                align-items: center;
                padding: 10px;
                span{
                    .icon{
                        color: var(--primaryColor)
                    }
                }
            }
            .failed{
                background:crimson;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 10px;
                span{
                    .icon{
                        color: crimson
                    }
                }
            }
            span{
                width: 30px;
                height: 30px;
                background: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;

            }
        }
    }
    .firstColumnContent, .secondColumnContent{
        margin-bottom: 30px;
    }
    @media screen and (max-width: 450px) {
        .contactWrapper{
            padding: 10px;
            .getinTouch{
                .first{
                    height: 40px;
                    .icon{
                        font-size: 17px;
                    }
                }
            }
        }
    }
}