.DonationContainer{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(36, 37, 39, 0.884);
    /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.12); */
    z-index: 999;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0px;
    .donationWrapper{
        // position: relative;
        // width: 650px;
        // height: fit-content;
        // margin: auto;
        // top: 5%!important;
        // background:#fff;
        // padding:20px 0px;
        // border-radius: 8px;
        // margin-bottom: 40px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 8px;
        padding: 20px 40px;
        width: 60%;
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            button{
                border: 1px solid var(--orangeColor);
                border-radius: 4px;
                background: var(--lightBackground);
                transition: 0.4s all ease;
                .icon{
                    color: var(--orangeColor)
                }
            }
            button:hover{
                background: var(--primaryColor);
                border: 1px solid var(--primaryColor);
                .icon{
                    color: #fff;
                }
            }
        }
        .text{
            padding-bottom: 20px;
            font-family: var(--textFamily);
            font-size: 14px;
            span{
                font-weight: 600;
                padding-left: 5px;
                font-size: 15px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .donationWrapper{
            width: 90%;
        }
    }
}