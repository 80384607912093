.BlogCard{
    .inner-box{
        position: relative;
        margin-bottom: 40px;
       
        .image{
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 270px;
        }
        .fullImage{
            width: auto;
            height:fit-content;
            position: relative;
            overflow: hidden;
        }
        .image{
            img{
                width: 100%;
                height: 100%;
                transition: .6s ease;
                transition-delay: .2s;
                display: inline-block;
            }
            .overlay {
                left: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                text-align: center;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                -webkit-transition: all 0.5s ease 0s;
                -o-transition: all 0.5s ease 0s;
                transition: all 0.5s ease 0s;
                z-index: 10;
                .link{
                    width: 60px;
                    height: 60px;
                    background: rgba(0, 0, 0, 0.30);
                    border-radius: 50%;
                    display: inline-block;
                    text-align: center;
                    height: 60px;
                    margin: 0 5px;
                    line-height: 60px;
                    transition: all 0.4s ease 0s;
                    cursor: pointer;
                    .icon{
                        color: #fff;
                        font-size: 25px;
                        transition: 0.4s all ease;
                    }
                }
                .link:hover{
                    background: #fff;
                    .icon{
                        color: var(--primaryColor)
                    }
                }
            }
        }
        .image:before {
            position: absolute;
            content: '';
            left: 0px;
            top: 0px;
            width: 100%!important;
            height: 100%!important;
            display: block;
            z-index: 10;
            opacity: 0;
            transform: perspective(400px) rotateX(-90deg);
            transform-origin: top;
            transition: all 0.5s;
            background: rgba(34, 139, 34, 0.8)!important;
        }

       
        .lower-box{
            border: 1px dotted var(--primaryColor);
            padding: 5px;
            position: relative;
            display: flex;
            .date{
                flex: 1;
                font-family: var(--textFamily);
                color: var(--blackColor);
                .day{
                    font-size: 30px;
                    font-weight: 600;
                }
                .month{
                    font-size: 16px;
                    text-transform: uppercase;
                    position: relative;
                    top:-5px;
                }
            }
            .otherContent{
                flex: 4
            }
            .postMeta{
                font-family: var(--text2Family);
                color: #7a7a7a;
                font-size: medium;
                padding-bottom: 10px;
                span{
                    color: var(--primaryColor);
                    font-weight: 600;
                }
            }
            .title{
                font-family: var(--textFamily);
                color: var(--blackColor);
                font-size: 20px;
                font-weight: 600;
                position: relative;
                padding-bottom: 7px;
                margin-bottom: 15px;
                text-transform: capitalize;
            }
            .title:before{
                position: absolute;
                content: '';
                left: 0;
                bottom: -5px;
                width: 30px;
                height: 2px;
                background-color: var(--primaryColor);
                transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
            }
            .title:after {
                position: absolute;
                content: '';
                left: 0;
                bottom: 2px;
                width: 15px;
                height: 2px;
                background-color: var(--primaryColor);
                transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
            }
            .text{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 20px;
                font-family: var(--text2Family);
                font-size: 15px;
                color: var(--lightBlack);
                line-height: 27px;
            }
            .fulltext{
                display: block;
                -webkit-line-clamp:none ; /* number of lines to show */
                line-clamp: block;
            }
            .readMore{
                position: relative;
                .read{
                    color: var(--blackColor);
                    font-family: var(--textFamily);
                    font-size: 16px;
                    font-weight: 600;
                }
            }
           
        }
        .lower-boxMainBlog{
            flex-direction: column;
            padding: 30px 50px;
            background: var( --lightBackground);
            border: none;
            .top{
                display: flex;
                align-items: flex-start;
                gap: 30px;
                width: 100%;
                justify-content: left;
                margin-bottom: 30px;
                .date{
                    flex: none;
                    background: var(--primaryColor);
                    padding: 10px 30px;
                }
                .title{
                    .posterName{
                        font-weight: normal;
                        font-size: 15px;
                        padding: 10px 0px;
                        .icon{
                            color: var(--primaryColor)
                        }
                    }
                }
            }
        }
        .lowerboxMopadding{
            padding: 30px 10px;
        }

    }
    .inner-box:hover{
        .image:before{
            opacity: 1!important;
            height: 100%;
            transform: perspective(400px) rotateX(0deg);
        }
        .image{
            .overlay{
                opacity: 1;
            }
            img{
                transform: scale(1.2);
            }
        }
    }
}