.blogContainer{
    padding: 70px 0px;
    width: 100%;
    position: relative;
}
.BlogRightSide{
    position: relative;
    padding: 0px 30px;
    .contentWrapper{
        text-decoration: none;
        color: var(--blackColor);
        display: flex;
        gap: 15px;
        margin-bottom: 20px;
        background: var(--lightBackground);
        .left{
            width: 4rem;
            height: 4rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .right{
            .title{
                color: var(--blackColor);
                font-weight: 600;
                padding-bottom: 10px;
            }
            .date{
                font-family: var(--textFamily);
                font-size: 12px;
            }
        }
    }
    .values{
        background: var(--lightBackground);
        .listItem{
            font-family: var(--text2Family);
            font-size: 14px;
            padding-bottom: 10px;
            span{
                padding-right: 5px;
                color: var(--orangeColor)
            }
        }
    }
}