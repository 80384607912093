.sliderWrapper{
  position: relative;
  height: 100vh;
}
.slider-overlay{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.603);
}
.sliderWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sliderWrapper .cover {
  padding: 75px 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.274);
  display: flex;
  align-items: center;
}
.sliderWrapper .cover .header-content {
  position: relative;
  padding: 56px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  color: #fff;
  h3{
    position: relative;
    color: #fff;
    font-size: 24px;
    line-height: 1.2em;
    padding: 0 50px;
    margin-bottom: 20px;
    font-family: var(--curveFamily);
    letter-spacing: 2px;
    display: inline-block;
    text-transform: uppercase;
  }
  h3::before{
    position: absolute;
    content: '';
    background-color: #fff;
    height: 2px;
    width: 15px;
    top: 12px;
    left: 15px;
  }
    h3::after{
        position: absolute;
        content: '';
        background-color: #fff;
        height: 2px;
        width: 30px;
        top: 18px;
        left: 0px;
    }
    h3 span{
        position: relative;
        display: block;
        top: -28px;
        right: -50px;
    }
    h3 span::before{
        position: absolute;
        content: '';
        background-color: #fff;
        height: 2px;
        width: 15px;
        top: 12px;
        right: 15px;
    }
    h3 span::after{
        position: absolute;
        content: '';
        background-color: #fff;
        height: 2px;
        width: 30px;
        top: 18px;
        right: 0px;
    }
    h1{
        width: 700px;
        font-weight: 700;
        text-align: center;
        margin: 0 auto;
        font-family: var(--headingFamily);
        font-size: 50px;
        padding: 15px 0px;
    }
    p{
        font-family: var(--textFamily);
        font-size: 20px;
        padding-bottom: 10px;
    }
}
.sliderWrapper .cover .header-content .line {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  left: 10%;
  top: 0;
  position: absolute;
  border: 9px solid var(--orangeColor);
  -webkit-clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
  clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
}
@media screen and (max-width: 1000px) {
  .sliderWrapper .cover .header-content .line{
    left: 2%;
  }
  .sliderWrapper .cover .header-content{
    h3{
      font-size: 18px;
    }
    h1{
      font-size: 45px;
      width: 650px;
   
    }
    p{
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 790px) {
  .sliderWrapper .cover .header-content{
    h1{
      width: 100%;
      font-size: 40px;
      padding: 30px 0px;
      line-height: 50px;
    }
  }
}
@media screen and (max-width: 450px) {
  .sliderWrapper .cover .header-content .line{
    left: 1%;
  }
  .sliderWrapper .cover .header-content{
    padding: 30px 10px;
    h3{
      font-size: 14px;
    }
    h1{
      font-size: 30px;
      padding: 20px 0px;
    }
    p{
      font-size: 15px;
      padding-bottom: 20px;
    }
  }
}
.owl-item.active h1 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInUp;
    animation-delay: 0.5s;
    animation-timing-function: ease;
}
.owl-item.active h3 {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: 0.8s;
    animation-timing-function: ease;
}
.owl-item.active p {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: bounceIn;
    animation-delay: 0.8s;
    animation-timing-function: ease;
}
.owl-item.active .line {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
    animation-delay: 0.5s;
}
  .owl-nav .owl-prev {
    position: absolute;
    left: 15px;
    top: 43%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: rgba(0, 0, 0, 0.5) !important;
    width: 50px;
    cursor: pointer;
    height: 60px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .owl-nav .owl-prev span {
    font-size: 2.6875rem;
    color: #fff;
  }
  .owl-nav .owl-prev:focus {
    outline: 0;
  }
  .owl-nav .owl-prev:hover {
    background: #000 !important;
  }
  .owl-nav .owl-next {
    position: absolute;
    right: 15px;
    top: 43%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: rgba(0, 0, 0, 0.5) !important;
    width: 50px;
    cursor: pointer;
    height: 60px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .owl-nav .owl-next span {
    font-size: 2.6875rem;
    color: #fff;
  }
  .owl-nav .owl-next:focus {
    outline: 0;
  }
  .owl-nav .owl-next:hover {
    background: #000 !important;
  }
  .pageContainer:hover .owl-prev {
    left: 0px;
    opacity: 1;
  }
  .pageContainer:hover .owl-next {
    right: 0px;
    opacity: 1;
  }
  .owl-theme .owl-nav {
   margin-top: 0px;
}