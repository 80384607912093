.homeCausesContainer{
    background: var(--lightBackground);
    width: 100%;
    padding: 50px 0px;
    .HomeCousesContent{
        padding: 50px 0px 20px;
        .contentContainer{
            width: 100%;
            padding-bottom: 20px;
            .imageContainer{
                position: relative;
                width: 100%;
                height: 270px;
                img{
                    width: 100%;
                    height: 100%;
                }
                .overlay {
                    position: absolute;
                    text-align: center;
                    left: 0px;
                    top: 0px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    width: 100%;
                    height: 0%;
                    background: rgba(34, 139, 34, 0.8);
                    -webkit-transition: .7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
                    -o-transition: .7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
                    transition: .7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
                    opacity: 1;
                    visibility: hidden;
                }
                .absoluteDonate{
                    position: absolute;
                    top:10px;
                    right: 0px;
                }
                .buttonNav{
                    border: 2px solid var(--primaryColor);
                }
            }
            .content{
                text-align: center;
                padding: 10px;
                border: 1px dotted var(--primaryColor);
                .subTitle{
                    font-size: 13px;
                    text-transform: uppercase;
                    font-family: var(--textFamily);
                    color: var(--blackColor);
                }
                .title{
                    font-family: var(--textFamily);
                    color: var(--blackColor);
                    font-size: 20px;
                    font-weight: 700;
                    padding: 10px 0px;
                    position: relative; 
                }
                span {   
                    position: relative;
                    display: block;
                    align-items: center;
                    text-align: center;
                    top:-5px;
                    padding-bottom: 20px;
                }
                span::before{
                    position: absolute;
                    content: '';
                    background-color: var(--primaryColor);
                    height: 2px;
                    width: 15px;
                    top: 0px;
                    left: 47%;
                    transition: 0.4s all ease;
                }
                span::after{
                    position: absolute;
                    content: '';
                    background-color: var(--primaryColor);
                    height: 2px;
                    width: 30px;
                    top: 5px;
                    right: 47%;
                }
                .text{
                    font-family: var(--text2Family);
                    line-height: 30px;
                    font-size: 14px;
                    padding-bottom: 20px;
                }
            }
        }
        .contentContainer:hover{
            .overlay{
                visibility: visible;
                height: 100%;
            }
            .content{
                span:before{
                    width: 50px;
                    left: 41%;
                }
            }
        }
    }
}