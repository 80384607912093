.homeContainer{
    // volunteer section starts here
    .VolunteerSection{
        background-image: url(../../Images/volunteer.jpg);
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center center;
        -webkit-background-size: cover;
        background-size: cover;
        padding: 50px 0;
        text-align: center;
        font-family: var(--textFamily);
        color: #fff;
        .pageTitle{
            color: #fff;
        }
        .text{
            font-family: var(--text2Family);
            line-height: 30px;
            font-size: 16px;
            max-width: 70%;
            margin: 0 auto;
            padding-bottom: 30px;
        }
        .subtext{
            font-size: 14px;
            font-family: var(--text2Family);
            line-height: 30px;
            font-style: italic;
            .buttonNav{
                margin: 20px 0px!important;
            }
        }
    }
    .HomeBlog{
        padding: 50px 0px;
    }
}