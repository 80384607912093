.topNavbar{
    background: #f7f9f3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 0px;
        .left{
            display: flex;
            align-items: center;
            gap: 15px;
            .content{
                display: flex;
                align-items: center;
                gap: 8px;
                font-family: var(--textFamily);
                .icon{
                    color: var(--primaryColor);
                    font-size: 20px;
                }
                .text{
                    font-size: 12px;
                    color: var(--lightBlack);
                    font-size: 14px;
                    font-family: var(--text2Family);
                    span{
                        font-weight: bold;
                        padding-left: 5px;
                        a{
                            text-decoration: none;
                            color: var(--lightBlack)
                        }
                    }
                }
            }
        }
        .right{
            display: flex;
            align-items: center;
            .socialMedia{
                display: flex;
                align-items: center;
                gap: 15px;
                a{
                    font-size: 18px;
                    color: var(--primaryColor)
                }
            }
        }
    }
}
@media screen and (max-width: 780px) {
    .topNavbar{
        .container{
            justify-content: center;
            flex-direction: column;
            gap: 4px;
            .left{
                flex-direction: column;
                gap: 4px;
            }
        }
    }
}

.navbar {
    background: #fff;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    z-index: 999;
    transition: 0.4s all ease;
}
.scrolled {
    position: fixed;
    background: #fff;
    box-shadow: 0px 15px 10px -15px var(--greenColor);
    top: 0px !important;
    color: #fff;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: 0.1s;
    animation-timing-function: ease-in-out;
}
.navbar-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-family: var(--textFamily); 
    .image{
        width: auto;
        img{
            width: 70px;
            height: 60px;
        }
    }  
    ul {
       display: flex;
       gap: 3rem;
       list-style: none;
       margin:0;
       padding: 0px;
       .nav-link {
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 1px;
            font-weight: 600;
            color: var(--blackColor);
            transition: 0.4s all ease;
            padding-top: 5px;
            font-family: var(--textFamily);
            position: relative;
        }
        .nav-link:hover {
            color: var(--primaryColor);
        }
        .nav-item {
            line-height: 90px;
        }
        
        .nav-item:after {
            content: "";
            display: block;
            height: 2px;
            width: 0;
            background: transparent;
            transition: width 0.7s ease, background-color 0.5s ease;
        }
        .nav-item:hover:after {
            width: 110%;
            background: transparent;
        } 
        .nav-item .active {
            color: var(--primaryColor);
        }
    }
    .rightHanded{
        display: flex;
        align-items: center;
        
    }
    .nav-icon {
        display: none;
    }
}

.nav-dropdown {
    position: absolute;
    top: 100px;
    background: var(--primaryColor);
    padding: 0;
    margin: 0;
    z-index: 999;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-width: 200px;
    display: none;
    .nav-dropdown-nav {
        text-decoration: none;
        color:#fff;
        font-family: var(--textFamily);
        text-transform: uppercase;
        font-size: 13px;
        width: 100%;
        font-weight: 700;
        transition: 0.4s all ease;
        display: block;
        margin: 0;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 45px;
        border-radius: 5px;
    }
}
.navlinkDrop{
    position: relative;
}
.nav-item:hover {
    .nav-dropdown{
        display: block!important;
    }
}
.nav-dropdown .nav-dropdown-nav:hover,
.nav-dropdown .active {
    color: var(--blackColor)!important;
}
@media screen and (max-width: 997px) {
    .navbar-container{
       ul {
           gap: 2rem;
        }
        .nav-link {
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 1px;
            font-weight: 600;
            color: var(--blackColor);
            transition: 0.4s all ease;
            padding-top: 5px;
            font-family: var(--textFamily);
        }
        .navDonate{
            display: none;
        }
    }
}
@media screen and (max-width: 768px) {
    .navbar{
        height: fit-content;
        padding: 10px 0px;
    }
    .navbar-container{
        flex-direction: column;
        padding: 5px;
        gap: 10px;
        .smallDeviceTop{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .nav-icon{
            display: block;
            font-size: 2rem;
            cursor: pointer;
            color: var(--primaryColor);
        }
       
        .rightHanded{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .navDonate{
                display: block;
            }
        }
        ul{
            .nav-item {
                line-height: 40px;
            }
        }
       
    }
    
    .nav-item:hover:after {
        width: 110%;
        background: transparent;
    }
    .nav-dropdown {
        position: relative;
        top: 0px;
        padding: 0;
        margin: 0;
        z-index: 999;
        border-radius: 5px;
        background: transparent;
        padding-left: 20px;
    }
    .nav-dropdown .nav-dropdown-nav:hover,
    .nav-dropdown .active {
        color: var(--primaryColor)!important;
    }
    .navbar-container ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 90px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        gap: 0px;
        // height: 100%;
        padding: 10px 30px !important;
        .nav-link {
            color: #fff;
        }
    }

    .navbar-container ul.active {
        background: #000;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        text-align: left;
        padding: 0px 30px 10px 30px;
        box-shadow: 5px 0px 5px 5px #888888;
        height: 100vh;
    }
}