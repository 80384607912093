:root{
    --headingFamily: 'Lemon', serif;
    --grayColor: #3f3e3e;
    --textFamily: 'Poppins', sans-serif;
    --text2Family: 'Lato', sans-serif;
    --curveFamily:  'Kalam', cursive;
    --primaryColor: #228B22;
    --lightGreen: #228b2223;
    --blackColor: #0c0c0c;
    --lightBlack: #464646;
    --lightBackground: #f8f9fa;
    --orangeColor: #FF4500;
  }
.buttonNav{
    position: relative;
    padding: 11px 40px;
    border-radius: 25px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    background: var(--primaryColor);
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 2px solid var(--primaryColor);
    font-family: 'Poppins', sans-serif;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    text-decoration: none;
}
.buttonNav:hover{
    background: #000;
}
.transparentButtonNav .buttonNav{
    background: transparent;
    border: 2px solid var(--blackColor);
    color: var(--orangeColor);
    text-decoration: none;
}
.transparentButtonNav .buttonNav:hover{
    background: var(--primaryColor);
    color: #fff;
    border: 2px solid var(--primaryColor)
}

.pageTitle{
    font-size: 45px;
    font-family: var(--textFamily);
    color: var(--blackColor);
    position: relative;
    font-weight: bold;
    span{
        color: var(--primaryColor);

    }
}

.pageSubTHeading{
    position: relative;
    margin-bottom: 10px;
    display: inline-block;
    padding-right: 50px;
    font-size: 25px;
    font-family: var(--curveFamily);
}
.pageSubTHeading:before {
    position: absolute;
    content: '';
    background-color: #111;
    height: 2px;
    width: 15px;
    top: 15px;
    right: 15px;
}
.pageSubTHeading:after {
    position: absolute;
    content: '';
    background-color: #111;
    height: 2px;
    width: 30px;
    top: 21px;
    right: 0px;
}

.pageSubText{
   font-family: var(--text2Family);
   color: var(--lightBlack);
   font-size: 15px; 
}