.pageHeaderContainer{
    .smallLogoDesign{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .icon{
            font-size: 40px;
            color: var(--primaryColor)
        }
        .pageText{
            font-family: var(--curveFamily);
            font-size: 22px;
        }
        .firstBefore{
            position: relative;
            display: block;
            top: -15px;
            left: -20px;
        }
        .firstBefore::before{
            position: absolute;
            content: '';
            background-color: var(--blackColor);
            height: 2px;
            width: 15px;
            top: 10px;
            right: 0px;
        }
        .firstBefore::after{
            position: absolute;
            content: '';
            background-color: var(--blackColor);
            height: 2px;
            width: 30px;
            top: 18px;
            right: 0px;
        }
        .secondBefore{
            position: relative;
            display: block;
            top: -15px;
            right: -50px;
        }
        .secondBefore::before{
            position: absolute;
            content: '';
            background-color: var(--blackColor);
            height: 2px;
            width: 15px;
            top: 10px;
            right: 15px;
        }
        .secondBefore::after{
            position: absolute;
            content: '';
            background-color: var(--blackColor);
            height: 2px;
            width: 30px;
            top: 18px;
            right: 0px;
        }
    }
    .pageTitle{
        text-align: center;
    }
}