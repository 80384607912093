.footerContainer{
    width: 100%;
    position: relative;
    background: var(--blackColor);
    font-family: var(--text2Family);
    color: #fff;
    padding: 70px 0px;
    .contentWrapper{
        .logoSection{
            img{
                width: 100px;
            }
        }
        .title{
            position: relative;
            font-size: 18px;
            font-weight: 600;
            text-transform: capitalize;
            margin-top: -5px;
            margin-bottom: 35px;
            padding-bottom: 14px;
            color: #ffffff;
        }
        .title::before{
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 15px;
            background-color: var(--primaryColor);
            content: "";
        }
        .title::after{
            position: absolute;
            left: 0;
            bottom: -6px;
            height: 2px;
            width: 30px;
            background-color: var(--primaryColor);
            content: "";
        }
        .content{
            .text{
                color: #e7e5e5;
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 30px;
            }
            .socialUrl{
                display: flex;
                align-items: center;
                gap: 15px;
                .social-link{
                    width: 40px;
                    height: 40px;
                    background: var(--lightBlack);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.4s all ease;
                    a{
                       color: #fff; 
                       font-size: 17px;
                    }
                }
                .social-link:hover{
                    background: var(--primaryColor);
                }
            }

            .dropIcon{
                display: flex;
                align-items: center;
                gap: 5px;
                padding-bottom: 10px;
                .icon{
                    color: var(--primaryColor);
                    font-size: 18px;
                }
            }
            .footer-nav{
                text-decoration: none;
                color: #fff;
                font-size: 16px;
            }
            .contact{
                display: flex;
                align-items: flex-start;
                gap: 10px;
                padding-bottom: 10px;
                .icon{
                    font-size: 20px;
                }
                .name{
                    font-size: 16px;
                    a{
                        text-decoration: none;
                        color:#fff;
                    }
                }
            }
        }
        
    }
}