.HomeTestimonyContainer{
    width: 100%;
    background: #f7f9f3;
    padding: 70px 0px;display:flex;
    align-items: center;
    justify-content: center;

    .contentWrapper{
        width: 70%;
        margin: 0 auto;
        text-align: center;
        .imageSession{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 30px;
            span{
                padding: 5px 15px;
                .icon{
                    font-size: 40px;
                    color: var(--primaryColor);
                }
            }
            .image{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: #e4e3e3;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon{
                    font-size: 50px;
                    color: #cac6c6
                }
            }
        }
        .content{
            .testimony{
                font-family: var(--curveFamily);
                font-size: 20px;
                color: var(--lightBlack);
                padding-bottom: 30px;
            }
            .name{
                font-family: var(--textFamily);
                font-size: 18px;
                font-weight: bold;
                text-transform: capitalize;
            }
        }
    }
    
}