.MissionGoalContainer{
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background: url('../../Images/mission.jpg');
    padding: 80px 0px;
    .mission-block{
        margin-top: 40px;
        .inner-box {
            width: 100%;
            height: 100%;
            position: relative;
            margin-bottom: 10px;
            -webkit-box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
            .caption {
                position: relative;
                padding: 70px 0 52px;
                background: #fff;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                text-align: center;
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                width: 100%;
            }
            
            .overlay-box {
                position: absolute;
                content: '';
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                display: block;
                opacity: 1;
                transform: perspective(400px) rotateX(-90deg);
                transform-origin: top;
                transition: all 0.5s;
                background-color: rgb(34, 139, 34);
                .overlay-inner {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    display: table;
                    text-align: center;
                    padding: 10px 30px;
                    vertical-align: middle;
                    color: #fff;
                    .text{
                        height: 120px;
                        line-height: 30px;
                        font-size: 15px;
                    }
                    span {
                        position: absolute;
                        left: 50%;
                        top: 30%;
                        -webkit-transform: translate(-50%);
                        -ms-transform: translate(-50%);
                        transform: translate(-50%);
                        font-size: 50px;
                        color: rgba(255, 255, 255, 0.1);
                    }
                    h4{
                        color: #fff;
                        font-size: 22px;
                        font-weight: 600
                    }
                }
            }
            h4 {
                font-size: 20px;
                font-weight: 600;
                color: #222;
                padding-top: 35px;
                position: relative;
            }
            h4:before {
                position: absolute;
                content: '';
                left: 50%;
                top: 15px;
                width: 15px;
                height: 2px;
                background-color: #94c13d;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }
            h4:after {
                position: absolute;
                content: '';
                left: 50%;
                top: 23px;
                width: 30px;
                height: 2px;
                background-color: #94c13d;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }
        .inner-box:hover .overlay-box{
            transform: perspective(400px) rotateX(0deg);
        }
    }
    
}