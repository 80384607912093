.HomeEventContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 60px;
    .imageSession{
        flex:1;
        img{
            width: 100%;
            height: fit-content;
        }
    }
    .contentContainer{
        flex: 1;
        padding: 40px;
        .subText{
            font-family: var(--text2Family);
            color: var(--lightBlack);
            font-size: 16px;   
            line-height: 30px;
            padding: 10px 0px;
        }
        .eventContainer{
            width: 100%;
            .date{
                font-family: var(--textFamily);
                color: var(--blackColor);
                font-size: 40px;
                span{
                    font-size: 20px
                }
            }
            .moderator{
                font-family: var(--textFamily);
                color: var(--blackColor);
                font-size: 13px;
                font-weight: 600;
                padding-bottom: 10px;
                span{
                    color: var(--primaryColor);
                    font-size: 13px;
                }
            }
            .title{
                font-family: var(--textFamily);
                color: var(--blackColor);
                font-size: 15px;
                font-weight: 600;
            }
            .timeLocation{
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 15px 0px;
                .first, .second{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 13px;
                    font-family: var(--text2Family);
                    font-weight: 600;
                    .icon{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .HomeEventContainer{
        flex-direction: column;
        gap: 10px;
    }
}