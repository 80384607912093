.AboutPageContainer{
    padding: 50px 0px 0px;
    width: 100%;
    position: relative;
    .imageSession{
        position: relative;
        width: 100%;
        .bigImage{
            img{
                width: 100%;
                border-radius: 20px;
            }
        }
        .smallImage{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            img{
                width: fit-content;
                position: absolute;
                bottom:-130px;
                right:0;
                right: 0px;
                border-radius: 20px
            }
        }
        @media screen and (max-width: 992px) {
            
            .smallImage{
                align-items: flex-start;
                justify-content: flex-start;
                img{
                    position: relative;
                    bottom: 50px;
                    left:0;
                }
            }
        }
    }
    .aboutText{
        font-family: var(--text2Family);
        color: var(--lightBlack);
        line-height: 30px;
        padding-bottom: 30px;
        text-align: center;
    }
    .mission{
        background-color: var(--lightBackground);
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        padding: 20px 10px;
        .first{
            flex: 1;
            height: 60px;
            border-radius: 50%;
            background: var(--orangeColor);
            display: flex;
            align-items: center;
            justify-content: center;
            .icon{
                color: #fff;
                font-size: 30px;
            }
        }
        .second{
            flex: 9;
            .title{
                font-family: var(--textFamily);
                font-weight: 600;
                font-size: 16px;
                padding-bottom: 10px;
            }
            .text{
                font-family: var(--text2Family);
                color: var(--lightBlack);
                font-size: 15px;
            }
        }
    }
    .bannerSession{
        padding: 50px 0px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        background: linear-gradient(45deg,
        rgba(12, 8, 8, 0.75),
        rgba(9, 12, 15, 0.75)), 
        url('../../Images/aboutbanner.jpg');
        margin-top: 30px;
        .image{
            width: 100%;
            img{
                width: 100%;
            }
        }
        .aboutList{
            .listItem{
                padding-bottom: 10px;
                font-family: var(--text2Family);
                font-size: 16px;
                span{
                    padding-right: 10px;
                    .icon{
                        color: var(--orangeColor)
                    }
                }
            }
        }
    }
}