.contentCardContainer{
    background: var(--lightBackground);
    width: 100%;
    padding: 70px 0px;
    .homeServices{
        position: relative;
        margin-bottom: 50px;
        text-align: center;
        .inner-box{
            padding: 60px 30px 40px;      
            background: #fff;
            border-bottom: 3px solid var(--primaryColor);
            -webkit-box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
            transition-duration: 0.3s;
            -webkit-transition-property: transform;
            transition-property: transform;
            max-height: 350px;
            position: relative;
        }
        .hvr-float-shadow{
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: transform;
            transition-property: transform;
        }
        .hvr-float-shadow:before{
            pointer-events: none;
            position: absolute;
            z-index: -1;
            content: '';
            top: 100%;
            left: 5%;
            height: 15px;
            width: 90%;
            opacity: 1;
            background: -webkit-radial-gradient(center, ellipse, #ff4400d0 0%, rgba(0, 0, 0, 0) 80%);
            background: radial-gradient(ellipse at center, #ff4400d0 0%, #d3171700 80%);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: transform, opacity;
            transition-property: transform, opacity;
            display: none;
        }
        .inner-box:hover{
            transform: translateY(-5px);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        }
        .hvr-float-shadow:hover:before{
            display: block;
        }
        .inner-box:hover h4:after{
            width: 50px;
        }
       
        h4{
            position: relative;
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 20px;
            padding-top: 15px;
            margin-bottom: 20px;
            font-family: var(--textFamily);
            color: var(--blackColor);
            a{
                color: var(--blackColor);
                text-decoration: none;
            }
        }
        h4:before {
            position: absolute;
            content: '';
            left: 50%;
            bottom: -5px;
            width: 30px;
            height: 2px;
            background-color: var(--primaryColor);
            transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        h4:after {
            position: absolute;
            content: '';
            left: 50%;
            bottom: 2px;
            width: 15px;
            height: 2px;
            background-color: var(--primaryColor);
            transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        .text{
            font-family: var(--text2Family);
            color: var(--lightBlack);
        }
        .helping{
            position: absolute;
            bottom: -20px;
            background: #fff;
            box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
            text-align: center;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            left: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon{
                color: var(--primaryColor);
                font-size: 20px;
            }
        }
    }
}