.HomeAboutSession{
    width: 100%;
    background: var(--lightBackground);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 100px;

    .imageSession{
        flex: 5;
        position: relative;
        .bigImage{
            img{
                width: 90%;
                object-fit: cover;
                height: fit-content
            }
        }
        .smallImage{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            img{
                width: fit-content;
                position: absolute;
                bottom:-100px;
                right:0;
                right: 0px;
            }
        }
        
    }
    .homeAboutContentContainer{
        flex: 5;
        padding: 10px 40px;
        .text{
            font-family: var(--text2Family);
            color: var(--lightBlack);
            line-height: 30px;
            padding-bottom: 20px;
        }
    }
}
@media screen and (min-width: 900px) and (max-width: 1115px) {
    .HomeAboutSession{
        .imageSession{
            .smallImage{
                img{
                    bottom:-170px;
                }
            }
            
        }
    }
}
@media screen and (max-width: 900px) {
    .HomeAboutSession{
        flex-direction: column;
        gap: 15px;
        .imageSession{
            .smallImage{
                align-items: center;
                justify-content: center;
                img{
                    position: relative;
                    bottom:-20px;
                }
            }
            
        }
        .homeAboutContentContainer{
            padding-top: 30px;
        }
    }
}