.eventContainer{
    padding-top: 20px;
    .lower-content{
        height: 270px;
    }
    .title{
        font-size: 17px;
    }
    .wrapper{
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        gap: 40px;
        .dateWrapper{
            background: var(--primaryColor);
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff;
            font-family: var(--textFamily);
            font-size: 26px;
            font-weight: 600;
            line-height: 25px;
            .month{
                font-size: 16px;
            }
        }
        .others{
            .organise{
                display: flex;
                align-items: center;
                font-family: var(--textFamily);
                padding-bottom: 10px;
                .beulah{
                    padding-left: 5px;
                    color: var(--primaryColor)
                }
            }
            .timeLocation{
                display: flex;
                align-items: center;
                gap: 16px;
                font-family: var(--text2Family);
                
                .icon{
                    color: var(--primaryColor);
                    font-size: 23px;
                    padding-right: 5px;
                }
                .first, .second{
                    font-size: 14px;
                    text-transform: capitalize;
                }
            }
        }
    }
}